import React, { useState, useEffect } from 'react'
import LoginCard from './LoginCard'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import theme from './theme'
import { parse } from 'query-string'

function App () {
  var [errorReason, setErrorReason] = useState('')
  var [errorReason2, setErrorReason2] = useState('')
  var [isLoading, setIsLoading] = useState(false)
  var [success, setSuccess] = useState(false)
  var [success2, setSuccess2] = useState(false)

  const [stage, setStage] = useState(0) // 0 -> user/pw, 1 -> code

  useEffect(() => {
    fetch('/api/v2/tokencheck').then((response) => {
      console.log(response)
      if (response.status === 200) {
        const queryparam = parse(window.location.search)
        setIsLoading(true)
        setSuccess('Bereits eingeloggt')
        if (queryparam.redirect !== 'false') {
          window.location = '/?redirect=false'
        }
      }
    })
  }, [])

  function submit (username, password, remember) {
    var form = new FormData()

    form.append('username', username)
    form.append('password', password)
    form.append('remember', remember)

    setIsLoading(true)
    fetch('/api/v2/token1',
      {
        method: 'POST',
        body: form,
        credentials: 'include'
      }).then((response) => {
      if (!response.ok) {
        setErrorReason('Fehler bei der Verbindung zum Authentifizierungsserver (' + response.statusText + ')')
        setIsLoading(false)
      } else {
        return response.json()
      }
    }).catch((e) => {
      setErrorReason('Fehler in der Antwort vom Authentifizierungsserver (' + e + ')')
      setIsLoading(false)
    }).then((data) => {
      if (data) {
        if (data.result === 'token') {
          localStorage.setItem('csrf_token', data.csrf_token)
          setSuccess('Login erfolgreich')
          setErrorReason(null)
          const queryparam = parse(window.location.search)
          if (queryparam.url) {
            window.location = queryparam.url
          } else if (queryparam.redirect !== 'false') {
            window.location = '/?redirect=false'
          }
        } else if (data.result === 'invalid credentials') {
          setErrorReason('Benutzername/Passwort ungültig')
          setIsLoading(false)
        } else if (data.result === 'challenge') {
          setSuccess('Login erfolgreich (2FA Challenge)')
          setIsLoading(false)
          setErrorReason(null)
          setStage(1)
        }
      }
    })
  }

  function submitCode (code) {
    var form = new FormData()

    form.append('code', code)

    setIsLoading(true)
    fetch('/api/v2/token2',
      {
        method: 'POST',
        body: form,
        credentials: 'include'
      }).then((response) => {
      if (!response.ok) {
        setConnectionError(response)
      } else {
        return response.json()
      }
    }).catch((e) => {
      setGenericError(e)
    }).then((data) => {
      if (data) {
        if (data.result === 'token') {
          loginSuccess(data)
          setSuccess2('Login erfolgreich')
          setErrorReason2(null)
          const queryparam = parse(window.location.search)
          if (queryparam.url) {
            window.location = queryparam.url
          }
        } else if (data.result === 'invalid code') {
          setErrorReason2('Code ungültig')
          setIsLoading(false)
        }
      }
    })
  }

  function setConnectionError (response) {
    setErrorReason('Fehler bei der Verbindung zum Authentifizierungsserver (' + response.statusText + ')')
    setIsLoading(false)
  }

  function setGenericError (e) {
    setErrorReason('Fehler in der Antwort vom Authentifizierungsserver (' + e + ')')
    setIsLoading(false)
  }

  function loginSuccess (data) {
    localStorage.setItem('csrf_token', data.csrf_token)
    setSuccess('Login erfolgreich')
    setErrorReason(null)

    const queryparam = parse(window.location.search)
    if (queryparam.url) {
      window.location = queryparam.url
    } else if (queryparam.redirect !== 'false') {
      window.location = '/?redirect=false'
    }
  }

  return (
    <div className='App'>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LoginCard
          successLabel={success} errorReason={errorReason} isLoading={isLoading} onSubmit={submit}
          successLabel2={success2} errorReason2={errorReason2}
          onSubmitCode={submitCode} stage={stage}
        />
      </ThemeProvider>
    </div>
  )
}

export default App
