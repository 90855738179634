import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import ForgotPasswordDialog from './ForgotPassword'

const useStyles = makeStyles((theme) => ({
  form: {
    margin: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))

function UserCredentials (props) {
  const classes = useStyles()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [remember, setRemember] = useState(true)

  const usernameRef = React.createRef()
  const passwordRef = React.createRef()

  const [pwOpen, setPwOpen] = useState(false)

  const handleUsernameChange = (event) => {
    setUsername(event.target.value)
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }

  const handleRememberChange = (event) => {
    setRemember(event.target.checked)
  }

  const handleUsernameKey = (event) => {
    if (event.key === 'Enter') {
      passwordRef.current.focus()
    }
  }

  const buttonDisabled = username === '' || password === '' || props.isLoading || props.disabled

  const handlePasswordKey = (event) => {
    if (event.key === 'Enter') {
      if (!buttonDisabled) {
        props.onSubmit(username, password, remember)
      }
    }
  }


  return (
    <div>{props.errorLabel}
      {props.successLabel}
      <form className={classes.form} noValidate>
        <TextField
          variant='outlined'
          margin='normal'
          required
          onKeyPress={handleUsernameKey}
          fullWidth
          disabled={props.isLoading}
          id='username'
          label='Benutzername'
          name='username'
          autoComplete='username'
          onChange={handleUsernameChange}
          value={username}
          autoFocus
          inputRef={usernameRef}
        />
        <TextField
          error={false}
          variant='outlined'
          margin='normal'
          required
          onKeyPress={handlePasswordKey}
          fullWidth
          disabled={props.isLoading}
          name='password'
          label='Passwort'
          type='password'
          id='password'
          onChange={handlePasswordChange}
          value={password}
          autoComplete='current-password'
          inputRef={passwordRef}
        />
        <FormControlLabel
          disabled={props.isLoading}
          onKeyPress={handlePasswordKey}
          control={<Checkbox color='primary' value={remember} defaultChecked onChange={handleRememberChange} />}
          label='angemeldet bleiben'
        />
        <Button
          onClick={() => props.onSubmit(username, password, remember)}
          fullWidth
          variant='contained'
          color='primary'
          className={classes.submit}
          disabled={buttonDisabled}
        >
                    anmelden
        </Button>
        <Grid container>
          <Grid item xs>
            <Link href='#' variant='body2' onClick={setPwOpen}>
                            Passwort vergessen?
            </Link>
            <ForgotPasswordDialog open={pwOpen} setOpen={setPwOpen} />
          </Grid>
        </Grid>
      </form>
    </div>)
}

export default UserCredentials
