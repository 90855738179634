import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

export default function ForgotPasswordDialog (props) {

  const handleClose = () => {
    props.setOpen(false)
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Password vergessen?</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Bitte wende dich an den Administrator
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary' autoFocus>
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
