import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Alert from '@material-ui/lab/Alert'

import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import SwipeableViews from 'react-swipeable-views'
import UserCredentials from './UserCredentials'
import SecondAuthCard from './SecondAuthCard'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh'
  },
  image: {
    backgroundImage: 'url(/login/limousinenservice-bg1-1200.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  alert: {
    width: '100%',
    marginTop: theme.spacing(2)
  }
}))

function Copyright () {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      2020
    </Typography>
  )
}

// var re = RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$')

function LoginCard (props) {
  const classes = useStyles()

  // const [passwordValid, setPasswordValid] = useState(true)

  // const validatePassword = (pw) => {
  //   console.log(re.exec(pw))
  //   return true
  // }*/

  var errorLabel = null
  if (props.errorReason) {
    errorLabel = <Alert className={classes.alert} severity='error'>{props.errorReason}</Alert>
  }

  var successLabel = null
  if (props.successLabel && !props.errorReason) {
    successLabel = <Alert className={classes.alert} severity='success'>{props.successLabel}</Alert>
  }

  var errorLabel2 = null
  if (props.errorReason2) {
    errorLabel2 = <Alert className={classes.alert} severity='error'>{props.errorReason2}</Alert>
  }

  var successLabel2 = null
  if (props.successLabel2 && !props.errorReason2) {
    successLabel2 = <Alert className={classes.alert} severity='success'>{props.successLabel2}</Alert>
  }

  return (
    <Grid container component='main' className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            First Class Services Einsatzmanagement
          </Typography>
          <SwipeableViews disabled index={props.stage}>
            <UserCredentials
              errorLabel={errorLabel}
              successLabel={successLabel}
              classes={classes}
              isLoading={props.isLoading}
              disabled={props.stage === 1}
              onSubmit={props.onSubmit}
            />
            <SecondAuthCard
              classes={classes}
              onSubmit={props.onSubmitCode}
              isLoading={props.isLoading}
              errorLabel={errorLabel2}
              successLabel={successLabel2}
              disable={props.stage === 0}
            />
          </SwipeableViews>

          <Box mt={5}>
            <Copyright />
          </Box>
        </div>

      </Grid>
    </Grid>
  )
}

export default LoginCard
