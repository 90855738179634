import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  form: {
    margin: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))

function SecondAuthCard (props) {
  const classes = useStyles()

  const [code, setCode] = useState('')
  const buttonDisabled = code === '' || props.isLoading || props.disable

  const handleCodeChange = (event) => {
    setCode(event.target.value)
  }

  const handleCodeKey = (event) => {
    if (event.key === 'Enter') {
      if (!buttonDisabled) {
        props.onSubmit(code)
      }
    }
  }

  return (
    <div style={{ marginTop: '40px' }}>
      {props.errorLabel}
      {props.successLabel}
      <div className={classes.form}>
        <Typography variant='body2'>Es wurde ein Code per SMS verschickt. Zur Bestätigung bitte eingeben:</Typography>
        <TextField
          variant='outlined'
          margin='normal'
          required
          onKeyPress={handleCodeKey}
          fullWidth
          disabled={props.isLoading || props.disable}
          id='username'
          label='Code'
          name='code'
          autoComplete='code'
          type='number'
          onChange={handleCodeChange}
          value={code}
          autoFocus
        />
        <Button
          onClick={() => props.onSubmit(code)}
          fullWidth
          variant='contained'
          color='primary'
          className={classes.submit}
          disabled={buttonDisabled}
        >
                    bestätigen
        </Button>
      </div>
    </div>)
}

export default SecondAuthCard
